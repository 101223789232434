import React, { useEffect } from "react";
import styled from "styled-components";
import "@stoplight/elements/styles.min.css";

// Estilos para o contêiner principal
const Container = styled.div`
  padding: 20px;
`;

const MessagesAPI = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://unpkg.com/@stoplight/elements/web-components.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container>
      <title>InnovationStudios</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <elements-api
        apiDescriptionUrl="https://raw.githubusercontent.com/innovationstudios/apiyaml/main/api.yaml"
        router="hash"
        layout="sidebar"
      />
    </Container>
  );
};

export default MessagesAPI;
